export const environment = {
    production: true,
  
    appUrl: 'gord-teste-frontend.desenvolvimento.prodemge.gov.br',
    apiUrl: 'http://gord-teste-frontend.desenvolvimento.prodemge.gov.br/*',
    service: 'http://gord-teste.desenvolvimento.prodemge.gov.br/api/v1',
    
    ssc_issuer: 'https://homologacao.ssc.prodemge.gov.br/ssc-idp-frontend/',
    ssc_redirectUri: 'http://gord-teste-frontend.desenvolvimento.prodemge.gov.br',
    ssc_clientId: 'Ki6GAjYbETDE73KVwWtPCYWd-12082019-gord',
    ssc_clientSecret: "FYriReqY17bpSiZPH96A0XAV",
    ssc_scope: 'openid gord:oauth001',
    ssc_discovery: 'https://homologacao.ssc.prodemge.gov.br/ssc-idp-frontend/rest/oidc/discovery',
    
};